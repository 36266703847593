<template>
  <div>
    <div class="flex h c" style="margin-bottom: 12px;">
      <el-button type="primary" icon="el-icon-arrow-left" @click="handleBack">返回</el-button>
      <div class="padding-0-20" v-if="form">
        <div class="fc-g" style="font-size: 12px;">当前活动{{isCopy}}</div>
        <div style="margin-top: 2px;">{{form.name || "-"}}</div>
      </div>
    </div>
    <el-tabs v-model="currentTab" v-if="form">
      <el-tab-pane label="基本信息" name="base">
        <el-form ref="form" :model="form" :rules="rules" label-position="top" hide-required-asterisk :class="{layout_disabled: !editable}">
          <el-form-item label="流水号" style="width: 400px;" v-if="form.code">
            <el-input readonly :value="form.code" />
          </el-form-item>
          <div class="h sb wrap" style="width: 840px;">
            <el-form-item label="活动名称" prop="name" style="width: 400px;">
              <el-input v-model.trim="form.name" :maxlength="40" placeholder="请输入活动名称" />
            </el-form-item>
            <el-form-item label="活动开始时间" prop="beginTime" style="width: 200px;">
              <el-date-picker style="width: 100%;" v-model="form.beginTime" type="datetime" format="yyyy/MM/dd HH:mm:ss" value-format="timestamp" default-time="00:00:00" />
            </el-form-item>
            <el-form-item label="活动结束时间" prop="endTime" style="width: 200px;">
              <el-date-picker style="width: 100%;" v-model="form.endTime" type="datetime" format="yyyy/MM/dd HH:mm:ss" value-format="timestamp" default-time="23:59:59" />
            </el-form-item>

            <el-form-item label="活动介绍" prop="info" style="width: 840px;">
              <el-input type="textarea" v-model="form.info" :maxlength="1000" placeholder="请输入活动介绍、说明等" :rows="3" resize="none" />
            </el-form-item>

            <el-form-item label="促销方式" prop="method" style="width: 400px;">
              <radio-group v-model="form.method" :options="methods" @change="handleClearItem('method', arguments[0], arguments[1])" />
            </el-form-item>
            <el-form-item label="促销条件" prop="proCondition" style="width: 400px;">
              <radio-group v-model="form.proCondition" :options="conditions" />
            </el-form-item>
            <div class="h sb" style="width: 840px;">
              <el-form-item label="商品范围" prop="goodsRange" style="width: 400px;">
                <radio-group v-model="form.goodsRange" :options="goodsRanges" />
              </el-form-item>
              <el-form-item label="商品范围类型" prop="goodsRangeSet" style="width: 400px;" v-if="form.goodsRange === 'part'">
                <radio-group v-model="form.goodsRangeSet" :options="goodsSets" />
              </el-form-item>
            </div>

            <el-form-item label="活动参与次数限制" prop="maxTimes" style="width: 400px;">
              <div class="h c">
                <el-input-number v-model.number="form.allMaxTimes" :min="0" :max="99999999" controls-position="right" @change="$checkNaN(form, 'allMaxTimes', 0)" style="width: 150px;" />
                <div class="padding-0-10 fc-g">0表示不限制次数</div>
              </div>
            </el-form-item>

            <el-form-item label="同一客户参与次数限制" prop="maxTimes" style="width: 400px;">
              <div class="h c">
                <el-input-number v-model.number="form.maxTimes" :min="0" :max="999999" @change="$checkNaN(form, 'maxTimes', 0)" controls-position="right" style="width: 150px;" />
                <div class="padding-0-10 fc-g">0表示不限制参与次数</div>
              </div>
            </el-form-item>

            <!-- <el-form-item label="互斥活动" style="width: 840px;">
              <el-checkbox v-model="form.mutuallyOrderAmount">整单促销(针对金额促销)</el-checkbox>
              <el-checkbox v-model="form.mutuallyOrderGiveaway">整单促销(针对买赠促销)</el-checkbox>
              <el-checkbox v-model="form.mutuallyGoodsAmount">商品促销(针对买赠促销)</el-checkbox>
              <el-checkbox v-model="form.mutuallyGoodsGiveaway">商品促销(商品金额促销)</el-checkbox>
            </el-form-item>-->

            <el-form-item prop="giftAddUp" style="width: 400px;">
              <div slot="label" class="h c">
                <b>累计叠加&nbsp;</b>
                <el-tooltip placement="top">
                  <div slot="content" style="width: 240px; line-height: 1.5;">
                    当订单每满足同一条件时，可获得的赠品进行累计叠加。
                    <br />例如：设置订单金额每满1000赠送1个赠品，则订单金额在达到2000时可获得2个赠品，以此类推。
                  </div>
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
              <el-checkbox v-model="form.giftAddUp" :disabled="form.method !== 'gift'">启用</el-checkbox>
            </el-form-item>
            <div class="h" style="width: 840px;">
              <el-form-item label="促销维度" prop="targetType" style="width: 150px; margin-right: 10px;">
                <quick-select v-model="form.targetType" :options="targetTypes" display-field="label" :before-change="handleTargetTypeBeforeChange" @change="handleTargetTypeChange" style="width: 100%;" />
              </el-form-item>
              <el-form-item label="　" key="_target_type_1" class="flex" v-if="form.targetType=='distributor'">
                <datagrid-picker v-model="form.target" multiple submit url="api/distributor" value-field="enterpriseId" query-value-field="ids" :query-define="distributorQueryDefine" :show-index="false" placeholder="请选择经销商" style="width: 100%;">
                  <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
                    <el-input v-if="$erp.enable()" v-model="distributorQueryDefine.erpId" :validate-event="false" clearable placeholder="ERP编号搜索" @clear="scope.query" @keyup.enter.native="toQuery" style="width: 130px;" />
                    <el-input :maxlength="20" v-model="distributorQueryDefine.name" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入经销商名称进行搜索" style="width: 240px;" />
                    <el-button type="success" icon="el-icon-search" @click.stop="scope.query" />
                  </div>
                  <el-table-column prop="erpId" label="ERP编码" min-width="130" v-if="$erp.enable()" />
                  <el-table-column prop="name" label="经销商" min-width="160" />
                  <el-table-column label="所在地区" min-width="200" :formatter="v=>{return v.provinceName+'/'+v.cityName + '/' + v.districtName}" />
                </datagrid-picker>
              </el-form-item>
              <el-form-item label="　" key="_target_type_2" class="flex" v-else-if="form.targetType=='distributorType'">
                <datagrid-picker v-model="form.target" multiple submit url="api/distributorLeve" query-value-field="ids" :show-index="false" placeholder="请选择经销商类型" style="width: 100%;">
                  <el-table-column prop="name" label="经销商类型" min-width="160" />
                </datagrid-picker>
              </el-form-item>
              <el-form-item label="　" key="_target_type_3" class="flex" v-else-if="form.targetType=='distributorGroup'">
                <datagrid-picker v-model="form.target" multiple submit url="api/distributorGroup" query-value-field="ids" :query-define="distributorGroupQueryDefine" :show-index="false" placeholder="请选择经销商组" style="width: 100%;">
                  <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
                    <el-input :maxlength="20" v-model="distributorGroupQueryDefine.name" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入经销商组名称进行搜索" style="width: 240px;" />
                    <el-button type="success" icon="el-icon-search" @click.stop="scope.query" />
                  </div>
                  <el-table-column prop="name" label="经销商组名称" min-width="160" />
                </datagrid-picker>
              </el-form-item>
              <el-form-item label="　" key="_target_type_4" class="flex" v-else-if="form.targetType=='distributorArea'">
                <quick-cascader v-model="form.target" url="api/area" multiple flat placeholder="请选择区域" filterable style="width: 100%;" />
              </el-form-item>
            </div>
          </div>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="活动明细" name="detail">
        <div class="h c" style="margin-bottom: 12px;" v-if="editable">
          <el-button type="primary" @click="handleAddItem">添加明细</el-button>
          <el-button type="danger" @click="handleClearItem(null)">清空明细</el-button>
        </div>
        <el-table :data="form._items" border :style="{width: widths[form.method]}">
          <el-table-column key="condition1" label="条件（满额）" width="180" v-if="form.proCondition">
            <template slot-scope="scope">
              <price-input v-model="scope.row.reachCondition" :min="0" style="width: 100%;" v-if="editable" />
              <span v-else>{{$price(scope.row.reachCondition)}}</span>
            </template>
          </el-table-column>
          <el-table-column key="condition2" label="条件（满件）" width="180" v-else>
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.reachCondition" :min="0" :max="9999999" :step="1" :precision="0" controls-position="right" style="width: 100%;" v-if="editable" />
              <span v-else>{{scope.row.reachCondition}}</span>
            </template>
          </el-table-column>
          <el-table-column key="methodDiscount" label="打折" v-if="form.method ==='discount'">
            <template slot-scope="scope">
              <percent-input v-model="scope.row.discount" :precision="1" :max="10" :base="1" symbol="折" style="width: 100%;" v-if="editable" />
              <span v-else>{{Math.round(scope.row.discount * 1000) / 100}}折</span>
            </template>
          </el-table-column>
          <el-table-column key="methodSave" label="减价" v-else-if="form.method ==='save'">
            <template slot-scope="scope">
              <price-input v-model="scope.row.saveAmount" :min="0" style="width: 100%;" v-if="editable" />
              <span v-else>{{$price(scope.row.saveAmount)}}</span>
            </template>
          </el-table-column>
          <el-table-column key="methodPrice" label="一口价（单价）" v-else-if="form.method ==='price'">
            <template slot-scope="scope">
              <price-input v-model="scope.row.price" :min="0" style="width: 100%;" v-if="editable" />
              <span v-else>{{$price(scope.row.price)}}</span>
            </template>
          </el-table-column>
          <el-table-column key="methodGift" min-width="240" label="赠品" class-name="el-table--inner-column transparent" v-else-if="form.method === 'gift'">
            <template slot-scope="scope">
              <div v-for="(r, ri) in scope.row.giftRules" :key="r._id">
                <div class="bc-l padding-10-15 h c row-commands" style="border-bottom: #ececec solid 1px;">
                  <div class="flex">
                    <b>赠品名目{{ri + 1}}</b>
                  </div>
                  <template v-if="editable">
                    <el-button type="text" size="mini" @click="handleAddGift(scope.row, r)">添加赠品</el-button>
                    <el-button type="text" size="mini" class="danger" @click="handleRemoveGiftRule(scope.row, r)">删除赠品名目</el-button>
                  </template>
                </div>
                <el-table :data="r.goodsList" class="el-table-inner" empty-text="还没有选择赠品">
                  <el-table-column min-width="240" label="赠品名称">
                    <template slot-scope="g">
                      <span>{{g.row.name}}</span>
                      <span class="fc-g">（{{$goodsSpecConvert(g.row.specs)}}）</span>
                    </template>
                  </el-table-column>
                  <el-table-column width="140" label="单价">
                    <template slot-scope="gift">
                      <price-input v-model="gift.row.price" :min="0" :controls="false" style="width: 100%;" v-if="editable" />
                      <span v-else>{{$price(gift.row.price)}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column width="100" label="数量">
                    <template slot-scope="gift">
                      <el-input-number v-model="gift.row.quantity" :min="0" :max="9999999" :step="1" :precision="0" :controls="false" style="width: 100%;" v-if="editable" />
                      <span v-else>{{gift.row.quantity}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column width="50" v-if="editable">
                    <div class="row-commands" slot-scope="gift">
                      <el-button type="text" size="mini" @click="handleRemoveGift(r, gift.row)">删除</el-button>
                    </div>
                  </el-table-column>
                </el-table>
              </div>
              <div class="row-commands padding-10-15" v-if="editable">
                <el-button type="text" @click="handleAddGiftRule(scope.row)">添加赠品名目</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="50" v-if="editable">
            <div class="row-commands" slot-scope="scope">
              <el-button size="mini" type="text" @click="handleRemoveItem(scope.row)">删除</el-button>
            </div>
          </el-table-column>
        </el-table>
        <div style="height: 22px;"></div>
      </el-tab-pane>

      <el-tab-pane :label="form.goodsRangeSet === 'whitelist' ? '白名单' :'黑名单'" name="goods" v-if="form.goodsRange === 'part'">
        <div style="margin-bottom: 12px;">{{form.goodsRangeSet === 'whitelist' ? "订单中包含以下商品时可以参加活动" : "订单中不包含以下商品时可以参加活动"}}</div>

        <div class="c h" style="margin-bottom: 12px" v-if="editable">
          <el-button type="primary" @click="handleGoodsSet">添加商品</el-button>
          <el-button type="danger" @click="handleClearGoods">清除全部商品</el-button>
        </div>

        <el-table :data="form.goodsRanges" border>
          <el-table-column label="商品编码" prop="code" width="150" />
          <el-table-column label="ERP编码" prop="erpCode" width="150" />
          <el-table-column label="商品名称" prop="name" min-width="360" />
          <el-table-column label="属性" prop="specs" min-width="180" :formatter="$goodsSpecConvert" />
          <el-table-column width="60" v-if="editable">
            <div class="row-commands" slot-scope="scope">
              <el-button size="mini" type="text" @click="handleRemoveGoods(scope.row)">删除</el-button>
            </div>
          </el-table-column>
        </el-table>
        <div style="height: 22px;"></div>
      </el-tab-pane>
    </el-tabs>
    <div class="h c padding-10-0">
      <el-button type="primary" @click="doSave" :disabled="operaing" :loading="saving" v-if="editable">保存整单优惠活动</el-button>
      <template v-if="form && form.id">
        <el-button type="danger" @click="doStop" :disabled="saving" :loading="operaing" v-if="form.status === 'start' && checkPermission(['MARKET_MANAGE','MARKET_MANAGE_START'])">反审整单优惠活动</el-button>
        <el-button type="success" @click="doStart" :disabled="saving" :loading="operaing" v-else-if="checkPermission(['MARKET_MANAGE','MARKET_MANAGE_START'])">审核整单优惠活动</el-button>
      </template>
    </div>

    <sku-selector ref="goodsSel" :title="selMode === 'gift' ? '选择赠品' : '选择商品'" request-url="api/goods/sku" :request-params="{onShelf: true}" multiple :exclude-keys="excludeSkusByCondition" :show-button="false" :show-details="false" @submit="handleGoodsSelect" />
  </div>
</template>

<script>
import { order } from "@/api/marketing";
import skuSelector from "@/views/assembly/skuSelect";
import checkPermission from "@/utils/permission";
export default {
  components: { skuSelector },
  props: {
    id: String | Number,
    isCopy: Boolean,
    readonly: Boolean,
  },
  data() {
    return {
      form: null,
      saving: false,
      operaing: false,
      maxCombinationIndex: 0,
      currentItem: null,
      currentGiftRule: null,
      methods: [
        { id: "discount", label: "打折" },
        { id: "save", label: "减价" },
        { id: "gift", label: " 赠商品" },
      ],
      widths: {
        discount: "480px",
        save: "480px",
        price: "480px",
        gift: "100%",
      },
      targetTypes: [
        { id: "all", label: "全部" },
        { id: "distributor", label: "经销商" },
        { id: "distributorType", label: "经销商类型" },
        { id: "distributorGroup", label: "经销商组" },
        { id: "distributorArea", label: "区域" },
      ],
      conditions: [
        { id: false, label: "满件" },
        { id: true, label: "满额" },
      ],
      goodsSets: [
        { id: "whitelist", label: "白名单" },
        { id: "blacklist", label: "黑名单" },
      ],
      goodsRanges: [
        { id: "all", label: "全部商品" },
        { id: "part", label: "部分商品" },
      ],
      distributorQueryDefine: {
        name: null,
        erpId: null,
      },
      distributorGroupQueryDefine: {
        name: "",
      },
      currentTab: "base",
      currentItem: null,
      currentGift: null,
      selMode: null,
      loading: false,
      defaultTime: ["00:00:00", "23:59:59"],
      dateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const now = new Date();
              const start = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
              );
              const end = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate() + 6,
                23,
                59,
                59
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const now = new Date();
              const start = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
              );
              const end = new Date(
                now.getFullYear(),
                now.getMonth() + 1,
                now.getDate(),
                23,
                59,
                59
              );
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const now = new Date();
              const start = new Date(
                now.getFullYear(),
                now.getMonth(),
                now.getDate()
              );
              const end = new Date(
                now.getFullYear(),
                now.getMonth() + 3,
                now.getDate(),
                23,
                59,
                59
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      rules: {
        name: [
          { required: "true", message: "活动名称为必填项", trigger: "blur" },
        ],
        beginTime: [
          {
            required: "true",
            message: "活动开始时间为必填项",
            trigger: "blur",
          },
        ],
        endTime: [
          {
            required: "true",
            message: "活动结束时间为必填项",
            trigger: "blur",
          },
        ],
        activeDateRange: [
          { required: "true", message: "活动时间为必填项", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    editable() {
      return this.form && this.form.status === "init" && !this.readonly;
    },
    excludeSkusByCondition() {
      if (this.selMode === "goods") {
        let os = [];
        (this.form._items || []).forEach((item) => {
          (item.rulesGoods || []).forEach((o) => {
            if (o.type === "sku" && o.targetId) os.push(o.targetId);
          });
        });
        return os;
      } else if (this.selMode === "gift" && this.currentItem) {
        let os = [];
        (this.currentItem.giftRules || []).forEach((r) => {
          (r.goodsList || []).forEach((o) => {
            os.push(o.goodsId);
          });
        });
        return os;
      }
      return null;
    },
  },
  methods: {
    checkPermission,
    handleBack() {
      this.$emit("cancel");
    },
    handleAddItem() {
      this.maxCombinationIndex++;
      let o = {
        _id: this.$uuid(),
        _maxGiftRuleIndex: 0,
        groupId: this.$uuid(),
        ladderId: this.$uuid(),
        reachCondition: 0,
        discount: 1,
        saveAmount: 0,
        isTopLimit: true,
        displayNo: this.maxCombinationIndex,
        giftRules: [],
      };
      this.form._items.push(o);
      if (this.form.method === "gift") {
        this.handleAddGiftRule(o);
      }
    },
    handleRemoveItem(item) {
      let inx = this.form._items.indexOf(item);
      if (inx >= 0) this.form._items.splice(inx, 1);
    },
    handleClearItem(field, val, oldVal = null) {
      if (field === "method" && val !== "gift") {
        this.form.giftAddUp = false;
      }
      if (this.form._items && this.form._items.length) {
        let tts = {
          method: "促销方式",
        };
        this.$confirm(
          field
            ? `更改【${tts[field]}】设置将清空已经设置的活动明细，确定要更改吗？`
            : `确定要清空已经设置的活动明细吗？`,
          "操作确认",
          {
            type: "warning",
          }
        )
          .then((_) => {
            this.form._items = [];
            this.maxCombinationIndex = 0;
          })
          .catch((_) => {
            if (field) {
              this.form[field] = oldVal === null ? !val : oldVal;
            }
          });
      }
    },

    handleAddGiftRule(item) {
      item._maxGiftRuleIndex++;
      item.giftRules.push({
        _id: this.$uuid(),
        _maxGiftIndex: 0,
        displayNo: item._maxGiftRuleIndex,
        groupId: item.groupId,
        ladderId: item.ladderId,
        goodsList: [],
      });
    },
    handleRemoveGiftRule(item, rule) {
      let inx = item.giftRules.indexOf(rule);
      if (inx >= 0) item.giftRules.splice(inx, 1);
    },

    handleAddGift(item, rule) {
      this.selMode = "gift";
      this.currentItem = item;
      this.currentGiftRule = rule;
      this.$refs.goodsSel && this.$refs.goodsSel.open();
    },
    handleRemoveGift(rule, gift) {
      let inx = rule.goodsList.indexOf(gift);
      if (inx >= 0) rule.goodsList.splice(inx, 1);
    },

    handleGoodsSet() {
      this.selMode = "goods";
      this.$refs.goodsSel && this.$refs.goodsSel.open();
    },
    handleRemoveGoods(goods) {
      let inx = this.form.goodsRanges.indexOf(goods);
      if (inx >= 0) this.form.goodsRanges.splice(inx, 1);
    },
    handleClearGoods() {
      if (this.form.goodsRanges && this.form.goodsRanges.length) {
        this.$confirm(
          `确定要清空已经设置的${
            this.form.goodsRangeSet === "whitelist" ? "白名单" : "黑名单"
          }吗？`,
          "操作确认",
          {
            type: "warning",
          }
        ).then((_) => {
          this.form.goodsRanges = [];
        });
      }
    },

    handleGoodsSelect(res) {
      if (res && res.length) {
        if (this.selMode === "goods") {
          this.form._maxGoodsRangeIndex++;
          res.forEach((o) => {
            this.form.goodsRanges.push({
              _id: this.$uuid(),
              goodsId: o.id,
              goodsType: "sku",
              spuId: o.spuId,
              specs: o.specs,
              name: o.name,
              oldPrice: o.price,
              code: o.code,
              erpCode: o.erpCode,
              displayNo: this.form._maxGoodsRangeIndex,
            });
          });
        } else if (this.selMode === "gift" && this.currentGiftRule) {
          res.forEach((g) => {
            this.currentGiftRule._maxGiftIndex++;
            this.currentGiftRule.goodsList.push({
              _id: this.$uuid(),
              displayNo: this.currentGiftRule._maxGiftIndex,
              goodsId: g.id,
              specs: g.specs,
              name: g.name,
              code: g.code,
              erpCode: g.erpCode,
              price: 0,
              quantity: 1,
            });
          });
        }
      }
    },

    handleTargetTypeChange() {
      this.form.target = [];
    },
    handleTargetTypeBeforeChange() {
      return this.$confirm(
        "切换促销纬度将会重置所选的维度中的数据,确定切换吗？",
        "提示",
        {
          type: "warning",
        }
      );
    },
    convertForm() {
      let form = null;
      if (this.form._items && this.form._items.length) {
        if (
          this.form.goodsRange === "all" ||
          (this.form.goodsRanges && this.form.goodsRanges.length)
        ) {
          form = JSON.parse(JSON.stringify(this.form));
          form.target = form.target.join(",");

          let arr = [],
            giftRules = [];
          let err = null;

          for (let i = 0, l = form._items.length; i < l; i++) {
            let item = form._items[i];

            if (form.method === "gift") {
              if (item.giftRules && item.giftRules.length) {
                item.giftRules.forEach((gr) => {
                  if (!gr.goodsList || !gr.goodsList.length) {
                    err = `明细${i + 1}中还没有设置赠品`;
                  }
                });
              } else {
                err = `明细${i + 1}中还没有设置赠品`;
              }
            }
            if (err) break;
            giftRules = giftRules.concat(item.giftRules);
            let bo = JSON.parse(JSON.stringify(item));
            delete bo.giftRules;
            arr.push(bo);
          }

          if (err) {
            this.$alert(err, "系统提示", { type: "warning" });
            form = null;
          } else {
            form.wholeOrderDetails = arr;
            form.giftRules = giftRules;
            delete form._items;
          }
        } else {
          this.$alert(
            `请设置参加活动的${
              this.form.goodsRangeSet === "whitelist" ? "白名单" : "黑名单"
            }`,
            "系统提示",
            { type: "warning" }
          );
          this.currentTab = "goods";
        }
      } else {
        this.$alert("请设置整单优惠明细", "系统提示", { type: "warning" });
        this.currentTab = "detail";
      }
      return form;
    },

    doSave(callback) {
      this.$refs["form"]
        .validate()
        .then((_) => {
          let form = this.convertForm();
          if (form) {
            this.saving = true;
            (this.form.id ? order.edit : order.add)(form)
              .then((res) => {
                this.$emit("update:isCopy", false);
                if (typeof callback === "function") callback();
                else {
                  this.$parent.init();
                  if (res && res.id) {
                    this.$emit("update:id", res.id);
                    this.$nextTick((_) => {
                      this.resetForm();
                    });
                  }
                  this.$notify({
                    title: "保存整单优惠活动成功",
                    type: "success",
                    duration: 2500,
                  });
                }
              })
              .finally((_) => {
                this.saving = false;
              });
          }
        })
        .catch((err) => {
          // this.$alert("请检查填写整单优惠活动的基本信息", "系统提示", {
          //   type: "warning"
          // });
          this.currentTab = "base";
        });
    },
    doStart() {
      let _exec = (_) => {
        this.operaing = true;
        order
          .start(this.form.id)
          .then((res) => {
            this.form.status = "start";
            this.$notify({
              title: "启用成功",
              type: "success",
              duration: 2500,
            });
            this.$parent.init();
          })
          .finally((_) => {
            this.operaing = false;
          });
      };
      if (this.form.status === "init") {
        this.$confirm(
          "启用整单优惠活动后，不能再对该活动内容进行编辑。<br />您确定要启用该整单优惠活动吗？",
          "操作确认",
          { type: "warning", dangerouslyUseHTMLString: true }
        ).then((_) => {
          this.doSave(_exec);
        });
      } else if (this.form.status === "stop") {
        this.$confirm("您确定要启用该整单优惠活动吗？", "操作确认", {
          type: "warning",
        }).then(_exec);
      }
    },
    doStop() {
      if (this.form.status === "start") {
        this.$confirm("您确定要停用该整单优惠活动吗？", "操作确认", {
          type: "warning",
        }).then((_) => {
          this.operaing = true;
          order
            .stop(this.form.id)
            .then((res) => {
              this.form.status = "stop";
              this.$notify({
                title: "停用成功",
                type: "success",
                duration: 2500,
              });
              this.$parent.init();
            })
            .finally((_) => {
              this.operaing = false;
            });
        });
      }
    },
    resetForm() {
      this.maxCombinationIndex = 0;
      if (this.id) {
        this.loading = true;
        order
          .get(this.id)
          .then((res) => {
            if (res.target) {
              if (res.targetType === "distributorArea") {
                res.target = (res.target || "").split(",");
              } else {
                res.target = (res.target || "").split(",").map(Number);
              }
            } else {
              res.target = [];
            }
            let obj = {},
              arrs = [];
            (res.wholeOrderDetails || []).forEach((o) => {
              if (o.displayNo > this.maxCombinationIndex)
                this.maxCombinationIndex = o.displayNo;
              if (!obj.hasOwnProperty(o.groupId)) {
                let item = Object.assign({}, o, {
                  _id: this.$uuid(),
                  _maxGiftRuleIndex: 0,
                  giftRules: [],
                });
                (res.giftRules || []).forEach((gr) => {
                  if (gr.promotionId === res.id && gr.groupId === o.groupId) {
                    if (item._maxGiftRuleIndex < gr.displayNo)
                      item._maxGiftRuleIndex = gr.displayNo;
                    gr._maxGiftIndex = 0;
                    (gr.goodsList || []).forEach((g) => {
                      if (g.displayNo > gr._maxGiftIndex)
                        gr._maxGiftIndex = g.displayNo;
                    });
                    item.giftRules.push(gr);
                  }
                });
                obj[o.groupId] = item;
                arrs.push(obj[o.groupId]);
              }
            });
            res._items = arrs;
            delete res.giftRules;
            delete res.wholeOrderDetails;
            if (this.isCopy) {
              res.name += "【复制】";
              res.id = null;
              res.status = "init";
              arrs.forEach((item) => {
                item.promotionId = null;
                item.id = null;
                item.groupId = this.$uuid();
                item.ladderId = this.$uuid();
                (item.giftRules || []).forEach((gr) => {
                  gr.id = null;
                  gr.promotionId = null;
                  gr.groupId = item.groupId;
                  gr.ladderId = item.ladderId;
                  (gr.goodsList || []).forEach(g => {
                    g.id = null;
                    g.giftRuleId = null;
                    g.promotionId = null;
                  });
                });
              });
            }
            this.form = res;
          })
          .finally((_) => {
            this.loading = false;
          });
      } else {
        this.form = {
          name: null,
          beginTime: null,
          endTime: null,
          targetType: "all",
          method: "discount",
          goodsRange: "all",
          proCondition: false,
          giftAddUp: false,
          goodsRangeSet: "whitelist",
          mutuallyOrderAmount: false,
          mutuallyOrderGiveaway: false,
          mutuallyGoodsAmount: false,
          mutuallyGoodsGiveaway: false,
          maxTimes: 0,
          allMaxTimes: 0,
          target: [],
          goodsRanges: [],
          _items: [],
          _maxGoodsRangeIndex: 0,
          status: "init",
        };
      }
    },
  },
  mounted() {
    this.resetForm();
  },
};
</script>